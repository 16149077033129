import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import '../styles/Hero'
import '../styles/TwoCols'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from "gatsby"

import ISOLATION from '../images/security/isolation.svg'
import ENCRYPTION from '../images/security/encryption.svg'
import IDENTITY from '../images/security/identity.svg'
import CODE from '../images/security/code.svg'
import DOCUMENTS from '../images/security/documents.svg'



export default function SecurityPage() {
  const root = useClasses()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/security/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout full dark>
      <SEO title='Security' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>
        <BackgroundImage className='hero-container' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='hero-inner'>
            <div className='hero-text'>
              <div className='hero-main'>Strong Security ...</div>
              <div className='hero-slanted'>Comes by Default</div>
            </div>
          </div>
        </BackgroundImage>

        <div className='container'>
          <div className="heading-separator"/>
          <h1 className="title">Security</h1>
          <h2 className="subtitle">Military-Grade Security</h2>
          <p className="description">
            With the increase in attack surfaces, it's time to do things right. 
            This page will go through the basics of online security and how we implement best-in-class technology, processes and tools to ensure your data & online experience are the most secure.
          </p>

          <div className="twocols">
            <div className="twocols-col-image">
              <img src={ISOLATION}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#1 - Isolation</div>
              <div className="heading">Only trust yourself</div>
              <div className="description">
                <p>This is the number 1 rule in security. There is only one trustworthy entity, and that's you.</p>
                <p>This is why governments insist on their own private servers. <span className='font-semibold text-primary'>SaaS (Software as a Service) is the #1 vulnerability online today.</span></p>
                <p>The challenge is cost, it's very expensive having all your tools on your own server.</p>
                <p className='quote'>This is OneOffice's main innovation -- We bundle all services to run on <strong>your</strong> servers.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={ENCRYPTION}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#2 - Encryption</div>
              <div className="heading">Everything is Encrypted</div>
              <div className="description">
                <p>This should go without saying. However, the key here is end-to-end (E2E) encryption.</p>
                <p className='quote'>End-to-end encryption is the most secure encryption, it uses keys that only you possess.</p>
                <p>Unlike legacy platforms all your calls, chats, even notifications are E2E encrypted. This is typically reserved for the most-sensitive communications.</p>
                <p>Your data deserves nothing less.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={IDENTITY}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#3 - Identity</div>
              <div className="heading">Securing Access 24/7</div>
              <div className="description">
                <p>We have systems in place to detect malicious behaviour.</p>
                <p>Brute-force attacks are stopped at their heel, suspicious access is detected very early.</p>
                <p>For additional security, you can opt-in to Two-Factor Authentication to ensure you are who you say you are.</p>
              </div>
            </div>
          </div>



          <div className="twocols">
            <div className="twocols-col-image">
              <img src={CODE}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#4 - Code & Processes</div>
              <div className="heading">Nothing Closed</div>
              <div className="description">
                <p>Hidden code means hidden hacks and bugs that criminals find before you. 'Security by obfuscation' has never worked. Ever wonder how come Apple, Amazon and Google are rarely hacked?</p>
                <p className='quote'>We leverage the best of open-source technologies, reviewed and audited by thousands of developers.</p>
                <p>Our processes are standardized and rigurously tested with a keen ability to move fast should issues arise. No ransomware, no viruses ...</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={DOCUMENTS}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#5 - Document Security</div>
              <div className="heading">Share the Strict Minimum</div>
              <div className="description">
                <p>Ever get worried about what happens when you share a document?</p>
                <p className='quote'>The OneOffice document editor opens document on-server so external users can't copy it.</p>
                <p>Fine-tuned sharing controls allow you to block downloads, timed unshares and enable file-drop only.</p>
                <p>Having doubts? Just check the history to see who did what.</p>
              </div>
            </div>
          </div>

          <CTA variant={4}/>
        </div>
      </main>
    </Layout>
  )
}
